.sortItem {
  height: 44px;
  border-bottom: 1px solid #e7eaf1;
  color: #333333;
  font-size: 12px;

  .num {
    width: 25%;
    font-size: 15px;
  }

  .avatar {
    width: 40%;

    .avatarImg {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  .money {
    width: 35%;
    font-size: 13px;
    color: #ff2121;
  }
}
