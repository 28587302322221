/*
 *  @ 文字颜色
 */
$active: #09f;
$white: #fff;
$black: #000;
$red: #ff2121;
$redprice: #ff3131;
$color_333: #333;
$color_555: #555;
$color_666: #666;
$color_888: #888;
$color_999: #999;
$color_606: #606060;
$color_bbb: #bbb;
$color_202426: #202426;
$color_4B4B4B: #4b4b4b;
$color_FE2F2F: #fe2f2f;
$color_FF4000: #ff4000;
$color_FF0000: #ff0000;
$color_525B69: #525b65;
$color_525B65: #000;

/*
 *  @ 文字大小
 */
$font_30: 30px;
$font_28: 28px;
$font_26: 26px;
$font_24: 24px;
$font_22: 22px;
$font_20: 20px;
$font_18: 18px;
$font_16: 16px;
$font_14: 14px;
$font_12: 12px;

/*
 *  @ 背景颜色
 */
$bg_active: #09f;
$bg_0078FF: #0078ff;
$bg_0080FF: #0080ff;
$bg_fff: #fff;
$bg_000: #000;
$bg_f4f4f4: #f4f4f4;
$bg_f5f5f5: #f5f5f5;
$bg_f7f9fc: #f7f9fc;
$bg_EBEFF5: #ebeff5;
$bg_ccc: #ccc;
$bg_333: #333;
$bg_666: #666;
$bg_999: #999;
$bg_82BBFB: #82bbfb;
$bg_EBC05A: #ebc05a;
$bg_EBA216: #eba216;
$bg_ff9898: #ff9898;
$bg_ff3131: #ff3131;
$bg_FFF8EB: #fff8eb;
$bg_FFE400: #ffe400;
$bg_FFA200: #ffa200;
$bg_FE2F2F: #fe2f2f;
$bg_FADD29: #fadd29;
$bg_E7E7E7: #e7e7e7;
$bg_18B4ED: #18b4ed;
$bg_FF4000: #ff4000;
$bg_FD7700: #fd7700;
$bg_FCCD05: #fccd05;
$bg_FF9500: #ff9500;
$bg_077FD0: #077fd0;
$bg_0198FE: #0198fe;
$bg_F4AAA7: #f4aaa7;
$bg_E02E24: #e02e24;
$bg_007FD0: #007fd0;
$bg_FF0000: #ff0000;
$bg_FFF4CE: #fff4ce;
$bg_FAFAFA: #fafafa;

/*
 *  @ 分割线颜色
 */
$sp_ddd: #ddd;
$sp_e7eaf1: #e7eaf1;

/*
 *  @ 边框颜色
 */
$border_ddd: #ddd;
$border_e7eaf1: #e7eaf1;
$border_f31: #f31;
$border_ccc: #ccc;

/*
 *  @ 标签颜色
 */
$E0B97B: #e0b97b;
