@import "src/assets/css/variable";
.scholarship {
  background-color: #f5f5f5;
  font-size: 15px;

  .common-ft-14 {
    font-size: 14px;
  }

  .common-ft-15 {
    font-size: 15px;
  }

  .common-ft-25 {
    font-size: 25px;
  }

  .account-container {
    background-color: #18b4ed;
    width: 100%;
    height: 158px;
    color: #fff;

    .back,
    .tip-info {
      font-size: 22px;
    }

    .draw-cash {
      width: 80px;
      height: 30px;
      line-height: 30px;
      border: 1px solid rgba(255, 255, 255, 1);
      border-radius: 15px;
      color: #fff;
      font-size: 16px;
      text-align: center;
    }

    .login-check {
      width: 90px;
      height: 30px;
      border: 1px solid rgba(255, 255, 255, 1);
      border-radius: 14px;
      color: #fff;
      line-height: 30px;
      text-align: center;
    }

    .drawCash {
      height: 24px;
      line-height: 24px;
    }
  }

  .runtimeList {
    height: 30px;
    background-color: #fff4ce;
    color: #333333;
    font-size: 12px;
    text-align: center;
    line-height: 30px;
  }

  .money {
    font-size: 13px;
    color: $bg_ff3131;
  }

  //.am-tabs-default-bar {
  //  border-bottom: 1px solid #E7EAF1;
  //}
  //.am-tabs-default-bar-top .am-tabs-default-bar-content, .am-tabs-default-bar-bottom .am-tabs-default-bar-content {
  //  display: flex;
  //  justify-content: space-around;
  //}
  //.am-tabs-default-bar-top .am-tabs-default-bar-tab {
  //  width: 60px !important;
  //}
  //.am-tabs-default-bar-top .am-tabs-default-bar-tab::after {
  //  background-color: #fff!important;
  //}
}
