@import "src/assets/css/variable";
.verify {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  height: 21px;
  padding-left: 7px;
  border: none;
  border-left: 1px solid $bg_ccc;
  color: $color_999;
  background: transparent;
  -webkit-appearance: none;
  font-size: 13px;

  &.active {
    color: $active;
  }
}
