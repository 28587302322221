// 公有样式引入variable的目的是为了方便修改页面统一的样式
@import "./variable.scss";

// 颜色设置
$border-color: #e9e9e9; // 边框颜色
$body-color: #333; // 设置通用的字体颜色
$body-bg: #fff; // 设置通用的 body 背景色
$link-color: #333; // 设置通用的链接颜色
$link-visited: #333; // 设置链接访问后的颜色
$main-color: #09f; // 主体颜色

// 字体
$font-family-zh: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
  "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
$font-family-en: Arial, sans-serif !default;

// 盒子模型
$box-model: border-box !default;

// 全局设置
// --------------------------------------------------

//
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0 none;
  font-size: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;

  /* 在X5新内核Blink中，在排版页面的时候，会主动对字体进行放大，会检测页面中的主字体，当某一块字体在我们的判定规则中，认为字号较小，并且是页面中的主要字体，就会采取主动放大的操作。然而这不是我们想要的，可以采取给最大高度解决 */
  max-height: 100000px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

em,
strong {
  font-style: normal;
}

ul,
ol,
li {
  list-style: none;
}

// 全局盒子模型设置
* {
  box-sizing: $box-model;
}

*:before,
*:after {
  box-sizing: $box-model;
}

// -webkit-tap-highlight-color 是一个 不规范的属性，它没有出现在 CSS 规范草案中。
// 当用户点击iOS的Safari浏览器中的链接或JavaScript的可点击的元素时，覆盖显示的高亮颜色。
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: $font-family-zh;
  line-height: 1.5;
  color: $body-color;
  background-color: $body-bg;
  //font-size: 0.24rem;
  font-size: 12px;
  padding-bottom: constant(safe-area-inset-bottom);
}

img {
  max-width: 100%;
}

// 这里主要应付 antd-mobile 的组件carousel 不能等比缩放的蛋疼问题
.home-swipe {
  height: 40.625vw;
  max-height: 406.25px;
}

// 字符溢出隐藏
.text-overflow-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 字符超出一行溢出隐藏
.text-overflow-one {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

// 字符超出两行溢出隐藏
.text-overflow-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

// 字符超出三行溢出隐藏
.text-overflow-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

// radio 样式重写
input[type="radio"] {
  position: relative;
  vertical-align: middle;
  width: 0.36rem;
  height: 0.36rem;
  -webkit-appearance: none !important;
  -moz-appearance: none;
  border: none;
  background: none;
  outline: none;
}

input[type="radio"]:before {
  position: absolute;
  content: "";
  display: block;
  width: 0.36rem;
  height: 0.36rem;
  border: 2px solid #999;
  background: #fff;
  left: 0;
  top: 0;
  z-index: 100;
  border-radius: 50%;
  outline: 0;
}

input[type="radio"]:checked:after {
  position: absolute;
  z-index: 50;
  content: "";
  display: block;
  width: 0.36rem;
  height: 0.36rem;
  border: 2px solid #999;
  background: #fff;
  left: 0;
  top: 0;
  border-radius: 50%;
  outline: 0;
}

input[type="radio"]:checked:before {
  position: absolute;
  z-index: 100;
  content: "";
  display: block;
  width: 0.18rem;
  height: 0.18rem;
  left: 0.09rem;
  top: 0.09rem;
  background: #1abc9c;
  border-radius: 50%;
  border: none;
}

.directions-p {
  font-size: 14px;
  line-height: 21px;
  color: #555555;
  font-weight: 300;
}

.directions-div {
  font-size: 13px;
  line-height: 24px;
  color: #333333;
  font-weight: 600;
}

.am-modal-button-group-h {
  .am-modal-button {
    font-size: 15px;
  }
}

.am-modal-alert-content,
.am-modal-propmt-content {
  color: #333;
  font-size: 15px;
}

.year19-index {
  display: block;
  position: fixed;
  top: 50%;
  right: 0;
  width: 100px;
  margin-top: -35px;
  z-index: 99;
  font-size: 12px;

  img {
    width: 100%;
    height: 100%;
  }

  .iconfont {
    $size: 26px;
    position: absolute;
    top: -($size + 8px);
    right: 0;
    font-size: $size;
    color: #5a5a5a;
  }
}

.modal-cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;

  .modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -65%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 300px;
    height: 130px;
    text-align: center;
    padding: 20px 20px;
    background: #fff;
    border-radius: 5px;
    font-size: 16px;
    color: #525c65;

    & > div {
      margin-bottom: 10px;
    }

    ._nc {
      height: 48px;
    }

    .stage {
      height: 48px;

      .slider {
        height: 48px;
        box-shadow: 0 0 3px #9a9a9a;

        .label,
        .track,
        .button {
          height: 48px;
        }
      }
    }
  }

  .close {
    position: absolute;
    bottom: -80px;
    left: 50%;
    margin-left: -18px;
    font-size: 36px;
    color: #fff;
  }

  #global-captcha {
    width: 100%;
    height: 48px;
    margin-bottom: 50px;
  }
}
