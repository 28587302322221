@import "src/assets/css/variable";
.login-button {
  display: block;
  width: 100%;
  height: 44px;
  line-height: 44px;
  font-size: $font_18;
  -webkit-appearance: none;
  color: $white;
  background: $bg_ccc;
  border: none;

  &.active {
    background-color: $active;
    color: $white;
  }
}
