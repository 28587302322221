@import "src/assets/css/variable";
.public-list-item {
  background: $bg_fff;

  .public-content {
    display: flex;
    height: 100%;

    .public-cover {
      margin-right: 15px;
      position: relative;
      width: 125px;
      height: 90px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 3px;
      }

      a {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          border-radius: 3px;
        }
      }
      .return_cash {
        position: absolute;
        top: 0;
        right: 0;
        width: 31px;
        height: 18px;
        background: url("./image/return.icon.png") no-repeat;
        background-size: 100% 100%;
      }
    }

    .order-info {
      position: relative;
      flex: 1;

      .order-title {
        font-size: 16px;
        color: $color_525B65;
      }

      .order-content {
        font-size: 14px;
        color: #777;
        margin-top: 10px;
        line-height: 18px;
      }

      .order-des {
        height: 24px;
        line-height: 33px;

        .order-newprice {
          font-size: 16px;
          color: $redprice;
        }

        .order-price {
          font-size: 12px;
          color: $color_999;
          margin-left: 15px;
          text-decoration: line-through;
        }
      }
    }
  }
}
