@import "src/assets/css/variable";
.custom-button {
  width: 300px;
  height: 44px;
  border-radius: 3px;
  -webkit-appearance: none;
  border: none;
  font-size: $font_18;
  color: $white;

  &.active {
    background: $active;
    color: $white;
  }
}
