@import "src/assets/css/variable";
.login-ways {
  flex: 0 0 auto;
  @mixin line($pos) {
    position: absolute;
    #{$pos}: 38px;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    display: inline-block;
    width: 80px;
    height: 1px;
    background: $bg_ccc;
  }

  .bottom-title {
    position: relative;
    font-size: $font_14;
    text-align: center;
    margin-bottom: 26px;

    &::before {
      @include line(left);
    }

    &::after {
      @include line(right);
    }
  }
}

.login-ways-container {
  display: flex;
  justify-content: space-around;
  padding: 0 30px 16px;

  li {
    text-align: center;

    a {
      display: block;
    }

    img {
      width: 34px;
    }

    p {
      font-size: $font_14;
      line-height: $font_14 + 22px;
      text-align: center;
    }
  }
}
