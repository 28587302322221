@import "src/assets/css/variable";
.binding-tel {
  padding: 35px 38px;

  .title {
    margin-bottom: 13px;
    font-size: $font_12;
    color: $color_666;
  }

  .verification {
    margin-bottom: 21px;
  }

  .complete-btn {
    background: $active;
    color: $white;
    margin-top: 34px;
  }
}
