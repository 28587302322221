.scholarship-category {
  display: flex;
  align-items: flex-start;
  height: 90px;
  background-color: #fff;
  margin-top: 15px;

  .imageContainer {
    flex: 0 0 auto;
    width: 125px;
    height: 90px;
    border-radius: 3px;
    margin-right: 15px;

    .image {
      width: 125px;
      height: 90px;
      border-radius: 3px;
    }
  }
  .content {
    flex: 1 1 auto;

    .priceNow {
      color: #ff3131;
      font-size: 15px;
      margin-right: 15px;
    }
    .pricePrimary {
      color: #999999;
      font-size: 12px;
      text-decoration: line-through;
    }
    .shareMoney {
      height: 28px;
      line-height: 28px;
      text-align: center;
      padding: 0 10px;
      color: #fff;
      font-size: 12px;
      background-color: #ff4000;
      border-radius: 3px;
    }
  }
  .am-tabs-default-bar-content .am-tabs-default-bar-tab {
    width: 100px !important;
  }
}
