@import "src/assets/css/variable";
.course-item {
  width: 47.8%;
  margin-top: 15px;
  margin-bottom: 5px;
  position: relative;
  img {
    width: 100%;
    height: 119px;
    min-height: 119px;
    border-radius: 4px;
    vertical-align: bottom;
  }
  .course-status {
    width: 100%;
    height: 24px;
    position: absolute;
    top: 95px;
    border-radius: 0 0 3px 3px;
    text-align: center;
    line-height: 24px;
    color: $white;
    font-size: 13px;
    background-color: #e02e24;
    opacity: 0.6;
  }

  .course-title {
    text-align: justify;
    height: 45px;
    font-size: 14px;
    color: $color_525B65;
    min-height: 38px;
    line-height: 22px;
    margin-top: 6px;
  }
}
