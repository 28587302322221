@import "src/assets/css/variable";
.clearable-input-wrapper {
  position: relative;

  input {
    width: 300px;
    height: 46px;
    padding: 15px 0 15px 34px;
    border: 1px solid $border_ccc;
    border-radius: 3px;
    -webkit-appearance: none;
    font-size: 15px;

    &::-webkit-input-placeholder {
      color: $color_999;
      font-size: $font_16;
    }
  }

  .iconfont {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 21px;
    color: $color_bbb;
  }

  .clear {
    right: 13px;
  }
}
