@import "src/assets/css/variable";
.input-wrapper {
  position: relative;
  width: 300px;
  height: 46px;
  margin: 0 auto;

  .input {
    width: 100%;
    height: 100%;
    padding: 15px 0 15px 34px;
    border: 1px solid $bg_ccc;
    border-radius: 3px;
    -webkit-appearance: none;
    font-size: $font_16;

    &::-webkit-input-placeholder {
      font-size: $font_16;
      color: $color_999;
    }
  }
  .iconfont {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: $font_20;
    color: $color_bbb;
  }
}
