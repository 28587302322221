.login {
  height: 100%;
  display: flex;
  flex-flow: column;
  flex: 1 0 auto;

  .login-info {
    padding: 0 38px;
    flex: 1 0 auto;

    /*.tel-input {
            margin-bottom: 24px;
        }*/

    .verification {
      margin-bottom: 24px;
    }

    .place {
      width: 100%;
      height: 39px;
      margin-bottom: 33px;
      background: #56abff;
    }
  }

  .student-root {
    padding-top: 18px;
    text-align: right;
  }

  .student-root__button {
    font-size: 15px;
    color: #0099ff;
    line-height: 1;
    cursor: pointer;
  }
}
