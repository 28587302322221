@import "src/assets/css/variable";
.set-password {
    position: relative;
    padding: 35px 38px;

    .title {
        margin-bottom: 12px;
        font-size: $font_12;
        color: $color_666;
    }

    .btn-active {
        margin-bottom: 20px;
        background: $active;
        color: $white;
    }

    .user-agreement {
        line-height: 30px;

        a {
            vertical-align: middle;
            color: $active;
        }

        input{
            -webkit-appearance: none;
            border: none;
            outline: 0;
            margin-right: 3px;
            font-size: 21px;
            vertical-align: middle;

            &.iconfont {
                color: $active;
            }

            &.disagree {
                padding: 2px;
                padding-top: 4px;

                &::before {
                    content: '';
                    display: inline-block;
                    width: 17px;
                    height: 17px;
                    border: 1px solid $border_ccc;
                    border-radius: 50%;
                }

            }
        }
    }

    .skip {
        margin-top: 84px;
        font-size: $font_14;
        color: $color_999;
        text-align: center;
    }

    .input-wrapper {
        input {
            padding-left: 17px;
        }
    }

}