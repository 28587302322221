.password-input {
  margin-bottom: 24px;

  .iconfont:last-child {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    font-size: 20px;
  }
}
