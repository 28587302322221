.forgot-password {
  height: 100%;

  .content {
    padding: 38px 36px;
    text-align: center;

    .to-email {
      line-height: 55px;
      font-size: 15px;
      color: #666;
    }
  }

  .place {
    width: 100%;
    height: 39px;
    margin-bottom: 33px;
    background: #56abff;
  }

  .verify-code {
    margin-bottom: 21px;
  }

  .next_step {
    margin-top: 32px;
  }
}
