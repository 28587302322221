@import "src/assets/css/variable";
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .loading-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    p {
      font-size: 14px;
      margin-top: 12px;
      color: $active;
    }
  }
}
