@import "src/assets/css/variable";
.account-login {
  height: 100%;
  display: flex;
  flex-flow: column;

  .login-info {
    padding: 0 38px;
    flex: 1;

    .tel-input {
      margin-bottom: 24px;
    }

    .verification {
      margin-bottom: 24px;
    }

    .place {
      width: 100%;
      height: 39px;
      margin-bottom: 33px;
      background: #56abff;
    }

    .forgot-password-btn {
      display: block;
      margin-top: 30px;
      text-align: center;
      font-size: $font_14;
      color: $color_bbb;
    }
  }

  .lock-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    font-size: 22px;
  }
}
